import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bet } from "../../models";
import { useMediaQuery } from "react-responsive";
import StockItem from "../StockItem";

import { getNameSymbolApi, IStocks } from "../../utils/stock";
import Button from "../common/Button";
import clsx from "clsx";
import nextIcon from "../../assets/img/svg/next.svg";
import { v4 as uuidv4 } from "uuid";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TypeOptionsHome } from "../../context/AuthContext";

interface StockListBoxProps {
  list?: IStocks;
  stocks: any;
  infoStocks: any;
  timeOption?: TypeOptionsHome;
  timeDelay?: number;
}

const StockListBox = ({
  list,
  stocks,
  infoStocks,
  timeOption,
  timeDelay = 2,
}): StockListBoxProps | any => {
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });

  return (
    <>
      <div
        className={clsx(
          "stockListBoxWrapper isDesktop" // isDesktop home_commonStocks home_commonStocks-stocksList
        )}
      >
        <div className="commonStocksWrapper stockListBox">
          {list?.data?.map((itemStock: any, index: number) => (
            <div key={index} className="stockItemWrapper">
              <StockItem
                // key={index}
                timeOption={timeOption}
                item={stocks.find(
                  (item: Bet) => item.name === getNameSymbolApi(itemStock.name)
                )}
                infoStock={infoStocks.find(
                  (item: any) => item.stock === getNameSymbolApi(itemStock.name)
                )}
                company={itemStock.company}
                avatar={itemStock.avatar}
                name={itemStock.name}
                timeDelay={timeDelay}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        className={clsx("stockListMobile isMobile")}
        style={list?.name == "Other Stocks" ? { marginBottom: "0px" } : {}}
      >
        <ScrollMenu
        // LeftArrow={LeftArrow}
        // RightArrow={RightArrow}
        // onWheel={onWheel}
        >
          {[...list?.data]?.map((itemStock: any, index: number) => (
            <div
              key={index}
              // className="stockItemWrapper"
              className="item"
            >
              <StockItem
                // key={index}
                timeOption={timeOption}
                item={stocks.find(
                  (item: Bet) => item.name === getNameSymbolApi(itemStock.name)
                )}
                infoStock={infoStocks.find(
                  (item: any) => item.stock === getNameSymbolApi(itemStock.name)
                )}
                company={itemStock.company}
                avatar={itemStock.avatar}
                name={itemStock.name}
                timeDelay={timeDelay}
              />
            </div>
          ))}
        </ScrollMenu>
      </div>
    </>
  );
};

export default StockListBox;

// https://lifesaver.codes/answer/drag-on-sliders-fires-a-click-event-848

// export const Slider = ({ children, sliderRef, ...props }) => {
//   const [dragging, setDragging] = useState(false);

//   const handleBeforeChange = useCallback(() => {
//     console.log("handleBeforeChange");
//     setDragging(true);
//   }, [setDragging]);

//   const handleAfterChange = useCallback(
//     (index) => {
//       console.log("handleAfterChange", index);
//       setDragging(false);
//     },
//     [setDragging]
//   );

//   const handleOnItemClick = useCallback(
//     (e) => {
//       // console.log("handleOnItemClick");
//       if (dragging) e.stopPropagation();
//     },
//     [dragging]
//   );

//   return (
//     <>
//       <Slick
//         beforeChange={handleBeforeChange}
//         afterChange={handleAfterChange}
//         ref={sliderRef}
//         {...props}
//       >
//         {React.Children.map(children, (child) => (
//           <div onClickCapture={handleOnItemClick}>{child}</div>
//         ))}
//       </Slick>
//     </>
//   );
// };
