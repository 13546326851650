import clsx from "clsx";
import { useRouter } from "next/router";

import getFeatherClient from "../../utils/featherClient";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext";

import CountUp from "react-countup";

import { IVoteStock } from "../VoteStocksList/VoteStockList";

interface VoteStockItemProps {
  item: IVoteStock;
  // name: string,
  company: string;
  avatar: string;
  handleVoteStock: any;
}

function VoteStockItem({
  item,
  company,
  avatar,
  handleVoteStock,
}: VoteStockItemProps) {
  const router = useRouter();
  const { isLoadingFetchUser, user } = useAuth();
  const handleClickToStock = (stock: string) => {
    router.push(`/daily/${stock}`);
  };

  const client = getFeatherClient();

  return (
    <>
      <div
        className={clsx(
          "voteStockItem",
          item?.voteUsers?.includes(user._id) && "voteStockItem-vote"
        )}
        onClick={() => handleVoteStock(item?.name)}
      >
        <img className="avatar" src={avatar} alt="" />
        <p className="title">{item?.name}</p>
        <p className="numVotes">{item?.voteUsers?.length} Votes</p>
        {/* <div className="voteStockItem_numVote">
          {item && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.843"
              height="10.007"
              viewBox="0 0 10.843 10.007"
            >
              <path
                id="Path_249"
                data-name="Path 249"
                d="M5.018,1.707a1.26,1.26,0,0,1,2.2,0L11.38,9.194a1.26,1.26,0,0,1-1.1,1.872H1.96a1.26,1.26,0,0,1-1.1-1.872Z"
                transform="translate(-0.698 -1.059)"
                fill="#fff"
                opacity="0.3"
              />
            </svg>
          )}
          <p>{item?.voteUsers?.length}</p>
        </div> */}

        {/* <div className="voteStockItem_info">
          <p className="name">{item?.name}</p>
          <p className="company">{company}</p>
        </div> */}
      </div>
    </>
  );
}

export default VoteStockItem;
