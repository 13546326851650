import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bet } from "../../models";
import { useMediaQuery } from "react-responsive";
import VoteStockItem from "../VoteStockItem";

import { IStocks } from "../../utils/stock";
import Button from "../common/Button";
import clsx from "clsx";
import nextIcon from "../../assets/img/svg/next.svg";

import getFeatherClient from "../../utils/featherClient";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMessage } from "../Comment";
import { useSnackbar } from "notistack";
import { useAuth } from "../../context/AuthContext";
import { messagesAPI, voteStocksAPI } from "../../utils/restClient";

interface VoteStockListProps {
  list?: IStocks;
  stocks: any;
  isHideMobile?: boolean;
  isFindDoneSpotlight: boolean;
}
export interface IVoteStock {
  name: string;
  voteUsers: any;
}

const VoteStockList = ({
  list,
  isFindDoneSpotlight = false,
}): VoteStockListProps | any => {
  const [voteStocks, setVoteStocks] = useState<IVoteStock[]>([]);
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const [inputVoteStock, setInputVoteStock] = useState<string>("");
  const { isLogged, useIsShowPopup } = useAuth();
  const [
    isShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupLogin,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;
  const voteStockService = getFeatherClient().service("vote-stocks");
  // const messagesService = getFeatherClient().service("messages");
  const { enqueueSnackbar } = useSnackbar();
  let sliderRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: isMobile ? 4 : 5,
    speed: 500,
    slidesToScroll: isMobile ? 3 : 4,
    dots: false,
    arrows: false,
    // touchThreshold: 5,
    initialSlide: 0,
    // cssEase: "linear",
    autoplaySpeed: 0,
  };

  const voteStocksRaw = [null, null, null, null, null, null, null, null];

  const handleOpenCreateVoteStock = () => {
    setIsOpenCreate(!isOpenCreate);
  };

  const handleChangeInputVoteStock = (e) => {
    setInputVoteStock(e.target.value);
  };

  const handleVoteStock = (symbol: string) => {
    if (!isLogged) {
      setIsShowPopupSignUp(true);
      return;
    }
    // voteStockAPI not working, fix later
    voteStockService
      .update(null, { symbol })
      .then((resVoteStock) => {
        console.log({ resVoteStock });
        const newVoteStock = voteStocks.map((voteStockItem: IVoteStock) =>
          resVoteStock?.newVoteStocks?.find(
            (resVoteStockItem: IVoteStock) =>
              resVoteStockItem.name == voteStockItem.name
          )
        );
        setVoteStocks(
          newVoteStock.filter(
            (item: any) => !(item?.name == "CORN" || item?.name == "TWTR")
          )
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleSubmitVoteStock = () => {
    console.log({ inputVoteStock });
    if (inputVoteStock !== "") {
      messagesAPI
        .create({
          body: `I’m voting for $${inputVoteStock} to be added to the Fan Votes section. Vote with me on the home page!`,
        })
        .then((res: IMessage) => {
          setInputVoteStock("");
          setIsOpenCreate(false);
          enqueueSnackbar("Vote Successfully !!", {
            variant: "success",
            autoHideDuration: 500,
          });
        })
        .catch((error: Error) => {
          enqueueSnackbar(error.message || "Error when vote !!", {
            variant: "error",
            autoHideDuration: 500,
          });
        });
    }
  };

  // const checkIndexSlide = (index) => {
  //   if (index == 0) {
  //     setIsBtnLeft(false);
  //   } else {
  //     setIsBtnLeft(true);
  //   }
  // };

  // start fix scrollbar investor mobile
  let firstClientX, clientX;
  const containerRef = useRef<any>(null);
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false,
        });
      }
    };
  });
  // end fix scrollbar investor mobile

  useEffect(() => {
    isFindDoneSpotlight &&
      !voteStocks.length &&
      Promise.all([voteStocksAPI.find()]).then(([resVoteStocks]) => {
        setVoteStocks(
          resVoteStocks
            .sort(
              (voteItem1: IVoteStock, voteItem2: IVoteStock) =>
                voteItem2.voteUsers.length - voteItem1.voteUsers.length
            )
            .filter(
              (item: any) => !(item?.name == "CORN" || item?.name == "TWTR")
            )
        );
      });
  }, [isFindDoneSpotlight]);

  return (
    <>
      <div className={clsx("home_title")}>
        <p>{list?.name}</p>
        {/* <p className="viewAll" onClick={() => router.push("/stocks")}>
                  View all
                </p> */}
        <div className="home_title_buttons">
          <Button
            className={clsx("btnPrev")} // !isBtnLeft && "disable")
            onClick={() => {
              (sliderRef?.current as any)?.slickPrev();
            }}
          >
            <>
              <img src={nextIcon.src} alt="prev" />
            </>
          </Button>
          <Button
            className="btnNext"
            onClick={() => {
              (sliderRef?.current as any)?.slickNext();
            }}
          >
            <>
              <img src={nextIcon.src} alt="next" />
            </>
          </Button>
        </div>
      </div>
      <div className={clsx("home_commonStocks")}>
        <div
          className="commonStocksWrapper commonStocksWrapper-vote isDesktop"
          ref={containerRef}
        >
          <Slider
            {...settings}
            sliderRef={sliderRef}
            // checkIndexSlide={checkIndexSlide}
          >
            {(voteStocks.length ? voteStocks : voteStocksRaw)?.map(
              (itemStock: any, index: number) => (
                <div key={index} className="voteStockItemWrapper">
                  <VoteStockItem
                    key={itemStock?.name || index}
                    item={itemStock}
                    // item={stocks.find(
                    //   (item: Bet) => item.name === itemStock.name
                    // )}
                    // name={itemStock.name}
                    company={
                      list.data.find(
                        (dataItem) => dataItem.name == itemStock?.name
                      )?.company || ""
                    }
                    avatar={
                      list.data.find(
                        (dataItem) => dataItem.name == itemStock?.name
                      )?.avatar || ""
                    }
                    handleVoteStock={handleVoteStock}
                  />
                </div>
              )
            )}
            <div className="voteStockItemWrapper">
              <div
                className={clsx(
                  "voteStockItemCreate",
                  isOpenCreate && "voteStockItemCreate-creating"
                )}
              >
                {!isOpenCreate ? (
                  <div
                    className="voteStockItemCreate_icon"
                    onClick={() => handleOpenCreateVoteStock()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.221"
                      height="16.221"
                      viewBox="0 0 16.221 16.221"
                    >
                      <path
                        id="Path_254"
                        data-name="Path 254"
                        d="M11.11,3a1.159,1.159,0,0,1,1.159,1.159V9.952h5.793a1.159,1.159,0,0,1,0,2.317H12.269v5.793a1.159,1.159,0,0,1-2.317,0V12.269H4.159a1.159,1.159,0,1,1,0-2.317H9.952V4.159A1.159,1.159,0,0,1,11.11,3Z"
                        transform="translate(-3 -3)"
                        fill="#fff"
                        fillRule="evenodd"
                        opacity="0.3"
                      />
                    </svg>
                  </div>
                ) : (
                  <>
                    <div className="voteStockItemCreate_input">
                      <span>$</span>
                      <input
                        value={inputVoteStock}
                        placeholder=""
                        onChange={handleChangeInputVoteStock}
                      />
                      <span
                        className="submitVoteStock"
                        onClick={() => handleSubmitVoteStock()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.138"
                          height="11.527"
                          viewBox="0 0 16.138 11.527"
                        >
                          <path
                            id="Path_255"
                            data-name="Path 255"
                            d="M18.8,5.338a1.153,1.153,0,0,1,0,1.63L9.578,16.189a1.153,1.153,0,0,1-1.63,0L3.338,11.578a1.153,1.153,0,1,1,1.63-1.63l3.8,3.8L17.17,5.338a1.153,1.153,0,0,1,1.63,0Z"
                            transform="translate(-3 -5)"
                            fill="#7f72da"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Slider>
        </div>

        {/* Mobile */}
        <div className="voteStocksMobile isMobile">
          <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          // onWheel={onWheel}
          >
            {(voteStocks.length
              ? [...voteStocks, "CREATE_VOTE"]
              : voteStocksRaw
            )?.map((itemStock: any, index: number) => {
              if (itemStock !== "CREATE_VOTE")
                return (
                  <div key={index} className="voteStockItemWrapper">
                    <VoteStockItem
                      key={itemStock?.name || index}
                      item={itemStock}
                      // item={stocks.find(
                      //   (item: Bet) => item.name === itemStock.name
                      // )}
                      // name={itemStock.name}
                      company={
                        list.data.find(
                          (dataItem) => dataItem.name == itemStock?.name
                        )?.company || ""
                      }
                      avatar={
                        list.data.find(
                          (dataItem) => dataItem.name == itemStock?.name
                        )?.avatar || ""
                      }
                      handleVoteStock={handleVoteStock}
                    />
                  </div>
                );
              else
                return (
                  <div className="voteStockItemWrapper">
                    <div
                      className={clsx(
                        "voteStockItemCreate",
                        isOpenCreate && "voteStockItemCreate-creating"
                      )}
                    >
                      {!isOpenCreate ? (
                        <div
                          className="voteStockItemCreate_icon"
                          onClick={() => handleOpenCreateVoteStock()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.221"
                            height="16.221"
                            viewBox="0 0 16.221 16.221"
                          >
                            <path
                              id="Path_254"
                              data-name="Path 254"
                              d="M11.11,3a1.159,1.159,0,0,1,1.159,1.159V9.952h5.793a1.159,1.159,0,0,1,0,2.317H12.269v5.793a1.159,1.159,0,0,1-2.317,0V12.269H4.159a1.159,1.159,0,1,1,0-2.317H9.952V4.159A1.159,1.159,0,0,1,11.11,3Z"
                              transform="translate(-3 -3)"
                              fill="#fff"
                              fillRule="evenodd"
                              opacity="0.3"
                            />
                          </svg>
                        </div>
                      ) : (
                        <>
                          <div className="voteStockItemCreate_input">
                            <span>$</span>
                            <input
                              value={inputVoteStock}
                              placeholder=""
                              onChange={handleChangeInputVoteStock}
                            />
                            <span
                              className="submitVoteStock"
                              onClick={() => handleSubmitVoteStock()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.138"
                                height="11.527"
                                viewBox="0 0 16.138 11.527"
                              >
                                <path
                                  id="Path_255"
                                  data-name="Path 255"
                                  d="M18.8,5.338a1.153,1.153,0,0,1,0,1.63L9.578,16.189a1.153,1.153,0,0,1-1.63,0L3.338,11.578a1.153,1.153,0,1,1,1.63-1.63l3.8,3.8L17.17,5.338a1.153,1.153,0,0,1,1.63,0Z"
                                  transform="translate(-3 -5)"
                                  fill="#7f72da"
                                  fillRule="evenodd"
                                />
                              </svg>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
            })}
            {/* */}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
};

export default VoteStockList;

// https://lifesaver.codes/answer/drag-on-sliders-fires-a-click-event-848

export const Slider = ({ children, sliderRef, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    console.log("handleBeforeChange");
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(
    (index) => {
      console.log("handleAfterChange", index);
      setDragging(false);
    },
    [setDragging]
  );

  const handleOnItemClick = useCallback(
    (e) => {
      // console.log("handleOnItemClick");
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <>
      <Slick
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        ref={sliderRef}
        {...props}
      >
        {React.Children.map(children, (child) => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </Slick>
    </>
  );
};
