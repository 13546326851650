import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TypeOptionsHome, useAuth } from "../../context/AuthContext";
import InvestorItem from "../InvestorItem";
import { IUserWinLoss } from "../top-moves";

interface InvestorListProps {
  usersWin: IUserWinLoss;
  usersLoss: IUserWinLoss;
  isWinLoss: any;
  timeOption: TypeOptionsHome;
}

const InvestorList = ({ timeOption = "DAILY" }): InvestorListProps | any => {
  const { isLogged, useIsShowPopup, useWinLoss } = useAuth();
  const [
    isShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupLogin,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;

  const {
    isWin,
    setIsWin,
    usersWinDaily,
    usersLossDaily,
    usersWinWeekly,
    usersLossWeekly,
    usersWinMonthly,
    usersLossMonthly,
  } = useWinLoss;

  let sliderRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: isMobile ? 4 : 4,
    speed: isMobile ? 200 : 500,
    slidesToScroll: isMobile ? 3 : 4,
    dots: false,
    arrows: false,
    // touchThreshold: 5,
  };
  // const checkIndexSlide = (index) => {
  //   if (index == 0) {
  //     setIsBtnLeft(false);
  //   } else {
  //     setIsBtnLeft(true);
  //   }
  // };

  const usersWin = () => {
    if (timeOption == "DAILY") {
      return usersWinDaily || [];
      // && setIsFetchUsersWinLoss(true);
    } else if (timeOption == "WEEKLY") {
      return usersWinWeekly || [];
    } else if (timeOption == "MONTHLY") {
      return usersWinMonthly || [];
    }
    return [];
  };

  const usersLoss = () => {
    if (timeOption == "DAILY") {
      return usersLossDaily || [];
      // && setIsFetchUsersWinLoss(true);
    } else if (timeOption == "WEEKLY") {
      return usersLossWeekly || [];
    } else if (timeOption == "MONTHLY") {
      return usersLossMonthly || [];
    }
    return [];
  };

  // start fix scrollbar investor mobile
  let firstClientX, clientX;
  const containerRef = useRef<any>(null);
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false,
        });
      }
    };
  });
  // end fix scrollbar investor mobile

  return (
    <>
      <div className="home_TopInvestors">
        <div className="TopInvestorsWrapper isDesktop" ref={containerRef}>
          <Slider
            {...settings}
            sliderRef={sliderRef}
            // checkIndexSlide={checkIndexSlide}
          >
            {(isWin
              ? [...usersWin(), ...usersWin(), ...usersWin()]
              : [...usersLoss(), ...usersLoss(), ...usersLoss()]
            )
              // .sort(
              //   (first: IUserWinLoss, second: IUserWinLoss) =>
              //     second.money - first.money
              // )
              .map((userWin: IUserWinLoss, index: number) => (
                <div key={index} className="investorItemWrapper">
                  <InvestorItem isWin={isWin} item={userWin} />
                </div>
              ))}
          </Slider>
        </div>

        <div className="TopInvestorsMobileWrapper isMobile">
          <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          // onWheel={onWheel}
          >
            {(isWin
              ? usersWin().length
                ? [...usersWin()]
                : [1, 2, 3, 4, 5, 6, 7]
              : usersLoss().length
              ? [...usersLoss()]
              : [1, 2, 3, 4, 5, 6, 7]
            )
              // .sort(
              //   (first: IUserWinLoss, second: IUserWinLoss) =>
              //     second.money - first.money
              // )
              .map((userWin: IUserWinLoss | any, index: number) => (
                <div key={index} className="investorItemWrapper">
                  <InvestorItem isWin={isWin} item={userWin} />
                </div>
              ))}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
};

export default InvestorList;

// https://lifesaver.codes/answer/drag-on-sliders-fires-a-click-event-848

export const Slider = ({ children, sliderRef, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    console.log("handleBeforeChange");
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(
    (index) => {
      console.log("handleAfterChange", index);
      setDragging(false);
    },
    [setDragging]
  );

  const handleOnItemClick = useCallback(
    (e) => {
      // console.log("handleOnItemClick");
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <>
      <Slick
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        ref={sliderRef}
        {...props}
      >
        {React.Children.map(children, (child) => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </Slick>
    </>
  );
};
