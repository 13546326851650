import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import _, { sumBy } from "lodash";
import { getStockAPI } from "../../utils/restClient";
import { TypeTimeOptionsActive } from "../symbol/BuySellBox";
import { IInfoSymbolSector } from "../symbol/SymbolPage";

interface SectorBoxProps {
  timeOption: TypeTimeOptionsActive;
}

interface IDateSectorMarket {
  DAILY: IInfoSymbolSector | null;
  WEEKLY: IInfoSymbolSector | null;
  MONTHLY: IInfoSymbolSector | null;
}

const SectorBox = ({ timeOption }: SectorBoxProps) => {
  const router = useRouter();
  const sectors = ["XLK", "XLF", "XLE", "XLP"];
  const [dataSectorMarket, setDataSectorMarket] = useState<IDateSectorMarket>({
    DAILY: null,
    WEEKLY: null,
    MONTHLY: null,
  });
  const time =
    timeOption == "DAILY"
      ? "tomorrow"
      : timeOption == "WEEKLY"
      ? "next week"
      : timeOption == "MONTHLY"
      ? "next month"
      : "";

  const calcTotalVolume = () => {
    // "XLK", "XLF", "XLE", "XLP"
    return (
      sumBy(
        sectors,
        (symbol) => dataSectorMarket[timeOption]?.[symbol].volume
      ) | 0
    );
  };

  const fetchDataSymbolSectors = () => {
    console.log("--fetchDataSymbolSectors--");
    getStockAPI
      .find({
        query: {
          // stock: symbolApi,
          timeOption: `${timeOption}_SECTOR`,
          // dateMetrics: metricsSymbol.expirationDate,
        },
      })
      .then((resSymbolSectors) => {
        console.log({ resSymbolSectors });
        resSymbolSectors?.data &&
          setDataSectorMarket({
            ...dataSectorMarket,
            [timeOption]: resSymbolSectors.data,
          });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  useEffect(() => {
    console.log("dataSectorMarket[timeOption]", dataSectorMarket[timeOption]);
    if (!dataSectorMarket[timeOption]) {
      fetchDataSymbolSectors();
    }
  }, [timeOption]);
  return (
    <>
      <div className="sectorBoxWrapper">
        <div className="sectorBox" onClick={() => {
          router.push(`/${timeOption.toLocaleLowerCase()}/XLK`);
        }}>
          <p className="title">{`Which sector will outperform ${time}?`}</p>
          <p className="text">Volume: {calcTotalVolume()}</p>
          <div className="sectorBox_items">
            {sectors.map((nameSymbol: string, index: number) => (
              <SectorBoxItem
                key={nameSymbol}
                timeOption={timeOption}
                symbol={nameSymbol}
                price={dataSectorMarket[timeOption]?.[nameSymbol]?.greenPercent}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectorBox;

interface SectorBoxItemProps {
  timeOption: TypeTimeOptionsActive;
  symbol: string;
  price: string | number;
}

const SectorBoxItem = ({ timeOption, symbol, price }: SectorBoxItemProps) => {
  const router = useRouter();
  const title =
    symbol == "XLK"
      ? "Technology (XLK)"
      : symbol == "XLF"
      ? "Finance (XLF)"
      : symbol == "XLE"
      ? "Energy (XLE)"
      : symbol == "XLP"
      ? "Consumer (XLP)"
      : "";

  const handleClickSector = (e) => {
    e.stopPropagation();
    router.push(`/${timeOption.toLocaleLowerCase()}/${symbol}`);
  };

  return (
    <div className="sectorBoxItem" onClick={handleClickSector}>
      <p className="name">{title}</p>

      <div
        className="sectorBoxItem_hoz"
        style={{ width: `${Number(price < 0.5 ? price : 0.5) * 100 * 2}%` }}
      >
        <p className="price">{Number(price) ? `$${Number(price)?.toFixed(2)}` : ""}</p>
      </div>
    </div>
  );
};

// export SectorBoxItem;
