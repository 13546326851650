import clsx from "clsx";
import { useRouter } from "next/router";
import { useAuth } from "../../context/AuthContext";
import SkeletonElement from "../SkeletonElement";
import { IUserWinLoss } from "../top-moves";
interface InvestorItemProps {
  item: IUserWinLoss;
  isWin?: boolean;
}

function InvestorItem({ item, isWin }: InvestorItemProps) {
  const { isLogged, useIsShowPopup } = useAuth();
  const [isShowPopupLogin, setIsShowPopupLogin] = useIsShowPopup;
  const router = useRouter();

  const handleClickToProfileUser = (name: string) => {
    !isLogged ? setIsShowPopupLogin(true) : router.push("/u/" + name);
  };
  return (
    <>
      <div
        className="investorItem"
        onClick={() => handleClickToProfileUser(item?.name)}
      >
        <div className="investorItem_avatar">
          <img
            src={
              item.img?.startsWith("Group")
                ? `/Profile Avatars/${item?.img}`
                : `/Profile Avatars/Group 639.png`
            }
            alt=""
          />

          {!item?.img && (
            <SkeletonElement theme="dark" className="skeleton-avatarInvestor" />
          )}
        </div>
        <div className="investorItem_name">
          <p>
            {item?.name ? (
              <>@{item?.name}</>
            ) : (
              <SkeletonElement theme="dark" className="skeleton-nameInvestor" />
            )}
          </p>
        </div>
        {item?.name ? (
          <div
            className={clsx(
              "investorItem_money",
              isWin ? "investorItem_money-win" : "investorItem_money-loss"
            )}
          >
            <p>
              {isWin ? "+" : "-"}${Number(item.money).toFixed(2)}
            </p>
          </div>
        ) : (
          <SkeletonElement theme="dark" className="skeleton-moneyInvestor" />
        )}
      </div>
    </>
  );
}

export default InvestorItem;
