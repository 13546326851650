import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { serverUrl, checkPromotionNewUserAPI } from "../../utils/restClient";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import moment from "moment";

const ClainMoney = () => {
  const { user } = useAuth();

  const [isNewUser, setIsNewUser] = useState<Boolean>(false);
  const [idPromotionNewUser, setIdPromotionNewUser] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  const fetchPromotionNewUser = async () => {
    await checkPromotionNewUserAPI
      .find()
      .then((resCheckPromotionNewUser: any) => {
        console.log({ resCheckPromotionNewUser });
        resCheckPromotionNewUser.data?.idPromotion && setIsNewUser(true);
        setIdPromotionNewUser(resCheckPromotionNewUser.data.idPromotion);
      })
      .catch((err) => {
        setIsNewUser(false);
        console.log({ err });
      });
  };

  const getMoney = async () => {
    axios
      .get(`${serverUrl}/promotion-confirm`, {
        params: {
          id: idPromotionNewUser,
        },
      })
      .then((resGetMoneyNewUser: any) => {
        console.log({ resGetMoneyNewUser });
        enqueueSnackbar(resGetMoneyNewUser.data.message, {
          variant: "success",
        });
        setIsNewUser(false);
      })
      .catch((error) => {
        console.log({ error });
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    // console.log({user});
    if (user._id && moment(user.createdAt).isAfter("2022-06-14")) {
      console.log(user._id);
      fetchPromotionNewUser();
    } else {
      setIsNewUser(false);
    }
  }, [user, setIsNewUser]);
  return (
    <>
      {isNewUser && idPromotionNewUser && idPromotionNewUser !== "" ? (
        <div className="home_claimMoney" onClick={() => getMoney()}>
          <p className="isDesktop">
            <span>Welcome to StockBet</span>{" "}
            <span>
              <img src="/icons/right_finger_svg.svg" alt="" />
              {/* <img src="/icons/hand.png" alt="" />  */}
            </span>
            <span>Click here to claim $10 for free!</span>
          </p>
          <p className="isMobile">
            <span>Welcome ! </span>
            <span>Click here to claim $10 for free!</span>
          </p>
        </div>
      ) : (
        <div className="home_claimMoney-notNewUser"></div>
      )}
    </>
  );
};

export default ClainMoney;
