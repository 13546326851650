import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { usePrevious } from "../../hook/hook";

function formatForDisplay(number = 0) {
  return parseFloat(Math.max(number, 0) as any)
    .toFixed(2)
    .split("")
    .reverse();
}

function DecimalColumn() {
  return (
    <div>
      <span>.</span>
    </div>
  );
}

function NumberColumn({ digit, delta, timeDelay = 2 }) {
  const [position, setPosition] = useState<any>(0);
  const [animationClass, setAnimationClass] = useState<any>("");
  const previousDigit = usePrevious(digit);
  const columnContainerRef = useRef();
  // console.log({ digit, delta, previousDigit });

  const [isAni, setIsAni] = useState<boolean>(false);

  const setColumnToNumber = (number) => {
    // console.log(number, (columnContainerRef?.current as any)?.clientHeight * parseInt(number, 10));
    setPosition(
      (columnContainerRef?.current as any)?.clientHeight * parseInt(number, 10)
    );
  };

  useEffect(
    () => setAnimationClass(previousDigit !== digit ? delta : ""),
    [digit, delta]
  );

  useEffect(() => {
    setColumnToNumber(digit);
  }, [digit]);

  useEffect(() => {
    setTimeout(() => {
      setIsAni(true);
    }, timeDelay * 1000);
  }, [setIsAni, timeDelay]);

  return (
    <div className="ticker-column-container" ref={columnContainerRef as any}>
      <motion.div
        animate={{ y: position }}
        transition={!isAni ? { duration: 0 } : {}}
        // transition={previousDigit ? { } : { duration: 0 }}
        className={`ticker-column ${animationClass}`}
        // onAnimationComplete={() => setAnimationClass("")}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div
            key={num}
            className="ticker-digit"
            // style={{opacity: 0}}
          >
            <span>{num}</span>
          </div>
        ))}
      </motion.div>
      <span className="number-placeholder">{0}</span>
    </div>
  );
}

export default function NumberScroll({ value, timeDelay = 2 }) {
  const numArray = formatForDisplay(value);
  const previousNumber = usePrevious(value) || 0;

  // console.log({ numArray, previousNumber });
  //  console.log( numArray );

  let delta: any = null;
  if (value > previousNumber) delta = "increase";
  if (value < previousNumber) delta = "decrease";

  return (
    <>
      {/* <CountUp
        decimal={"."}
        decimals={2}
        end={Number(value)}
        duration={0.2}
        separator=","
      /> */}
      <motion.div layout className="ticker-view">
        {numArray.map((number, index) =>
          number === "." ? (
            <DecimalColumn key={index} />
          ) : (
            <NumberColumn
              key={index}
              digit={number}
              delta={delta}
              timeDelay={timeDelay}
            />
          )
        )}
      </motion.div>
    </>
  );
}
