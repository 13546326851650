import { useRouter } from "next/router";
import clsx from "clsx";
import CountUp from "react-countup";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  BarChart,
  CartesianGrid,
  Bar,
  Legend,
} from "recharts";
import { useMediaQuery } from "react-responsive";

import config from "../../config.json";
import { getInfoStock, getNameSymbol, IStocksData } from "../../utils/stock";
import { TypeOptionsHome } from "../../context/AuthContext";
import { IDataChart } from "../symbol/ChartSymbolItem/ChartSymbol";
import { useEffect, useState } from "react";
import { calendarRestAPI, chartStockAPI } from "../../utils/restClient";
import moment from "moment-timezone";
import SkeletonElement from "../SkeletonElement";

interface ChartSpotlightProps {
  timeOption: TypeOptionsHome;
  dataChart: IDataChart[];
  infoBet: any;
  isLoadingHeader: boolean;
}

const ChartSpotlight = ({
  timeOption,
  dataChart,
  infoBet,
  isLoadingHeader = false,
}: ChartSpotlightProps) => {
  // console.log({ isLoadingHeader });
  const router = useRouter();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });

  const name = infoBet?.name;
  const infoStock: IStocksData | undefined = getInfoStock(getNameSymbol(name));

  // const [dataChart, setDataChart] = useState<IDataChart[]>([
  //   // { green: 0.5, red: 0.5, time: "" },
  //   // { green: 0.5, red: 0.5, time: "" },
  // ]);

  const [closeDay, setCloseDay] = useState<string>("");
  const [selectedChart, setSelectedChart] = useState<any>(null);

  useEffect(() => {
    const tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1)); //get next date from to day
    const tomorrowDateTz = moment(tomorrowDate)
      .tz(config.TIMEZONE_NEW_YORK)
      .format("YYYY-MM-DD");

    Promise.all([
      calendarRestAPI.find({
        query: {
          date: { $gte: tomorrowDateTz },
          $sort: { date: 1 },
          $limit: 1,
        },
      }),
    ]).then(([res]) => {
      console.log({ resCloseDay: res });
      const day = res.data[0];
      if (day) {
        // setDateBet(day?.date);
        day?.date && setCloseDay(moment(day.date).format("dddd"));
      }
    });
  }, []);

  useEffect(() => {
    const rechartsContainerElm = document.querySelector(
      ".recharts-responsive-container"
    ) as HTMLElement;
    rechartsContainerElm &&
      (rechartsContainerElm.style.height = isMobile ? "150px" : "250px");
  }, [isMobile]);

  return (
    <>
      <div
        className="chartStock chartStock-spotlight"
        onClick={() => {
          infoBet?.name &&
            router.push(
              `/${timeOption.toLocaleLowerCase()}/${getNameSymbol(
                infoBet?.name
              )}`
            );
        }}
      >
        <div className="chartStock_header">
          <div className="chartStock_header_logo">
            <img src={isLoadingHeader ? infoStock?.avatar : ""} alt="" />
            <SkeletonElement
              theme="dark"
              className="skeleton-avatarSpotlight"
            />
            {/* {infoStock?.avatar ? (
              <img src={infoStock?.avatar} alt="" />
            ) : (
              <SkeletonElement theme="dark" className="avatarSpotlight" />
            )} */}
          </div>

          <div className="chartStock_header_info">
            <p>
              {isLoadingHeader && infoBet?.volatility ? (
                <span
                  className={clsx(
                    "price",
                    String(infoBet?.volatility) === "0.00" //  (-0.00 >= 0 => true)
                      ? "red-text"
                      : String(infoBet?.volatility) === "0.00"
                      ? "green-text"
                      : infoBet?.volatility < 0
                      ? "red-text"
                      : "green-text"
                  )}
                >
                  {infoBet?.price && `$${Number(infoBet?.price).toFixed(2)}`}{" "}
                  {String(infoBet?.volatility) === "0.00" ? (
                    "0.00%"
                  ) : String(infoBet?.volatility) === "0.00" ? (
                    "(0.00%)"
                  ) : (
                    <>
                      {infoBet?.volatility > 0 ? "(+" : ""}
                      {infoBet?.volatility <= 0 ? "(" : ""}
                      {infoBet?.volatility
                        ? `${Number(infoBet?.volatility).toFixed(2)}%)`
                        : ""}
                    </>
                  )}
                </span>
              ) : (
                <SkeletonElement className="skeleton-spotlightText " />
              )}

              {isLoadingHeader && infoBet?.volatility && (
                <span
                  className={clsx(
                    "priceMobile",
                    String(infoBet?.volatility) === "0.00" //  (-0.00 >= 0 => true)
                      ? "red-text"
                      : String(infoBet?.volatility) === "0.00"
                      ? "green-text"
                      : infoBet?.volatility < 0
                      ? "red-text"
                      : "green-text"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.802"
                    height="12.483"
                    viewBox="0 0 7.802 12.483"
                  >
                    <path
                      id="Path_252"
                      data-name="Path 252"
                      d="M5.229,6.452a.78.78,0,0,1,0-1.1L8.349,2.229a.78.78,0,0,1,1.1,0l3.121,3.121a.78.78,0,1,1-1.1,1.1L9.681,4.664V13.7a.78.78,0,0,1-1.56,0V4.664L6.332,6.452A.78.78,0,0,1,5.229,6.452Z"
                      transform="translate(12.802 14.483) rotate(180)"
                      fill="#fb8081"
                      fillRule="evenodd"
                    />
                  </svg>
                  {infoBet?.volatility
                    ? `${Number(infoBet?.volatility).toFixed(2)}%`
                    : ""}
                </span>
              )}
              {isLoadingHeader && infoBet?.volatility ? (
                <span className="vol">
                  Vol.{" "}
                  <CountUp
                    decimal={"."}
                    decimals={0}
                    end={Number(
                      // case1 dataChart?.reduce(
                      //   (partial_sum, a) => partial_sum + a.currVolume,
                      //   0
                      // ) || 0
                      // case2 dataChart[dataChart.length - 1]?.volumeTotal || 0
                      infoBet?.volume || 0
                    )}
                    duration={0.3}
                    separator=","
                  />
                </span>
              ) : (
                <SkeletonElement className="skeleton-spotlightText-2 " />
              )}
            </p>

            {isLoadingHeader && infoBet?.name ? (
              <p className="question">
                Will {getNameSymbol(infoBet?.name)} close
                <label> Green</label> or
                <label> Red</label>
                <label>
                  {" "}
                  {timeOption == "WEEKLY"
                    ? "next week?"
                    : timeOption == "DAILY"
                    ? closeDay && closeDay !== ""
                      ? `on ${closeDay}?`
                      : ""
                    : `in ${moment()
                        .tz("America/New_York")
                        .endOf("month")
                        .add(1, "week")
                        .format("MMMM")}?`}
                </label>
              </p>
            ) : (
              <p className="question">
                <SkeletonElement className="skeleton-spotlightText-3 " />
              </p>
            )}
          </div>
        </div>

        <div
          className="chartStock_chartOne"
          onTouchEnd={() => {
            // console.log("onTouchEnd");
            // setSelectedChart(null);
            // const dotElm = document.querySelector(
            //   ".chartStock_chartOne_activeDot"
            // ) as HTMLElement;
            // dotElm.style.left = -10000 + "px";
            // dotElm.style.top = -10000 + "px";
          }}
          onTouchCancel={() => {
            // console.log("onTouchCancel");
            // setSelectedChart(null);
            // const dotElm = document.querySelector(
            //   ".chartStock_chartOne_activeDot"
            // ) as HTMLElement;
            // dotElm.style.left = -10000 + "px";
            // dotElm.style.top = -10000 + "px";
          }}
        >
          <div className="chartOneWrapper">
            <ResponsiveContainer width="100%" height={isMobile ? 150 : 250}>
              <AreaChart
                data={
                  dataChart.length
                    ? dataChart
                    : [
                        { green: 0.5, red: 0.5, time: "" },
                        { green: 0.5, red: 0.5, time: "" },
                      ]
                }
                // data={dataChart.map((item: any) => ({
                //   ...item,
                //   raw: 0.5,
                // }))}
                margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
                // onMouseMove={handleMoveChart}
                onMouseLeave={() => {
                  const dotElm = document.querySelector(
                    ".chartStock_chartOne_activeDot"
                  ) as HTMLElement;
                  dotElm.style.left = -10000 + "px";
                  dotElm.style.top = -10000 + "px";
                }}
              >
                <defs>
                  <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="1%" stopColor="#7f72da" stopOpacity={0.2} />
                    <stop offset="99%" stopColor="#7f72da" stopOpacity={0} />
                  </linearGradient>
                </defs>

                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  horizontalPoints={[isMobile ? 150 / 2 : 250 / 2 - 1]}
                  stroke="#6C6E71"
                />

                <YAxis type="number" domain={[0.35, 0.65]} hide={true} />

                <Area
                  type="linear"
                  isAnimationActive={true}
                  animationDuration={300}
                  dataKey={"green"}
                  stroke="#9176E0"
                  strokeWidth={2}
                  // dot={false}
                  dot={(props) => {
                    const { index, cx, cy, stroke, payload, value } = props;
                    if (!dataChart.length) {
                      const ringContainerElm = document.querySelector(
                        ".ring-container"
                      ) as HTMLElement;
                      ringContainerElm.style.display = "none";
                    } else if (index == dataChart.length - 1) {
                      const ringContainerElm = document.querySelector(
                        ".ring-container"
                      ) as HTMLElement;
                      ringContainerElm.style.left = `${cx - 4}px`;
                      ringContainerElm.style.top = `${cy - 4}px`;
                      ringContainerElm.style.display = "block";
                    }
                    return null;
                  }}
                  dotWidth={2}
                  fillOpacity={1}
                  fill="url(#colorValue)"
                  activeDot={(payload) => {
                    setSelectedChart(
                      dataChart.find(
                        (item: IDataChart) => item.time == payload?.payload.time
                      )
                    );
                    const dotElm = document.querySelector(
                      ".chartStock_chartOne_activeDot"
                    ) as HTMLElement;
                    dotElm.style.left = payload?.cx + "px";
                    dotElm.style.top = payload?.cy + "px";
                    return <></>;
                  }}
                />

                <Tooltip
                  cursor={false}
                  active={true}
                  content={
                    <>
                      <div className="chartStockTooltip">
                        <div className="time">
                          <p>{selectedChart?.time}</p>
                        </div>
                        <div className="price">
                          <p className="price_green">
                            {selectedChart?.green.toFixed(2)}
                          </p>
                          <p className="price_red">
                            {selectedChart?.red.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </>
                  }
                />
              </AreaChart>
            </ResponsiveContainer>
            {/* <div className="chartStock_chartOne_lineHoz"></div> */}
            <span className="chartStock_chartOne_activeDot" />
            <div className="ring-container">
              <div className="ringring"></div>
              <div className="circle"></div>
            </div>
          </div>
        </div>

        <div className="chartStock_chartButton">
          <button
            className="btn-green"
            style={{
              width: `${
                dataChart
                  ? Number(
                      dataChart[dataChart.length - 1]?.green ==
                        dataChart[dataChart.length - 1]?.red
                        ? 50
                        : dataChart[dataChart.length - 1]?.green * 100
                    )
                  : 0
              }%`,
            }}
          >
            Green $
            {(dataChart[dataChart.length - 1]?.green
              ? Number(dataChart[dataChart.length - 1]?.green)
              : 0.5
            ).toFixed(2)}
          </button>
          <button
            className="btn-red"
            style={{
              width: `${
                dataChart
                  ? Number(
                      dataChart[dataChart.length - 1]?.green ==
                        dataChart[dataChart.length - 1]?.red
                        ? 50
                        : dataChart[dataChart.length - 1]?.red * 100
                    )
                  : 0
              }%`,
            }}
          >
            Red $
            {(dataChart[dataChart.length - 1]?.red
              ? Number(dataChart[dataChart.length - 1]?.red)
              : 0.5
            ).toFixed(2)}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChartSpotlight;
