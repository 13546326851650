import { useAuth } from "../../context/AuthContext";
import Button from "../common/Button";
import bgChildHomeImg from "../../assets/img/svg/bgChildHome.svg";

const EarlyAccess = () => {
  const { useIsShowPopup } = useAuth();
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
    valueEmail,
    setValueEmail,
  ] = useIsShowPopup;
  return (
    <>
      <div className="home_top_earlyAccess">
        <p className="title">Trade Market Outcomes</p>
        <span className="lineHoz"></span>
        <p className="text">
          Forget complex derivatives and options greeks. <br /> Just choose
          Green or Red.
        </p>

        <div className="inputWrapper">
          <input
            value={valueEmail}
            placeholder="Your Email"
            type="text"
            onChange={(e) => {
              setValueEmail(e.target.value);
            }}
          />
          <Button
            className="btnGetEarlyAccess"
            text="Get Early Access"
            onClick={() => {
              setIsShowPopupLogin(false);
              setIsShowPopupSignUp(true);
            }}
          />
        </div>
        <img className="bgChild" src={bgChildHomeImg.src} alt="" />
        <img
          className="imgLeft"
          src={"/final_render_transparent_v1.png"}
          alt=""
        />
      </div>
    </>
  );
};

export default EarlyAccess;
