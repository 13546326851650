import React from "react";
import clsx from "clsx";
import CountUp from "react-countup";
import { useRouter } from "next/dist/client/router";
import { useAuth } from "../../context/AuthContext";
import SkeletonElement from "../SkeletonElement";

export interface UserWinLossItemProps {
  id: number;
  img: string;
  name: string;
  money: string | number;
  isWin: boolean;
  wantID: boolean;
}
export default function UserWinLossItem({
  id,
  img,
  name,
  money,
  isWin,
  wantID,
}: UserWinLossItemProps) {
  const { isLogged, useIsShowPopup } = useAuth();
  const [isShowPopupLogin, setIsShowPopupLogin] = useIsShowPopup;
  const router = useRouter();

  const handleClickToProfileUser = (name: string) => {
    !isLogged ? setIsShowPopupLogin(true) : router.push("/u/" + name);
  };
  return (
    <>
      <div
        className={clsx(
          "userWinLossItem",
          isWin ? "userWinLossItem-win" : "userWinLossItem-loss"
        )}
        onClick={() => handleClickToProfileUser(name)}
      >
        {img ? (
          <img
            src={
              img?.startsWith("Group")
                ? `/Profile Avatars/${img}`
                : `/Profile Avatars/Group 639.png`
            }
            alt="userWinLoss"
          />
        ) : (
          <SkeletonElement className="skeleton-topMoves-avatar" />
        )}

        {name ? (
          <p className="name">{name}</p>
        ) : (
          <SkeletonElement
            className="skeleton-topMoves-name"
            style={{ width: Math.random() * (135 - 70) + 70 }}
          />
        )}

        {money == 0 || money ? (
          <p className={clsx("money", isWin ? "money-win" : "money-loss")}>
            {`${isWin ? "+" : "-"}$`}
            {(money === 0 ? (
              "0.00"
            ) : (
              <CountUp
                decimal={"."}
                decimals={2}
                end={Number(money)}
                duration={0.2}
                separator=","
              />
            )) || "0"}
          </p>
        ) : (
          <SkeletonElement className="skeleton-topMoves-value" />
        )}
      </div>
    </>
  );
}
