import clsx from "clsx";
import { useRouter } from "next/router";
// import { v4 as uuidv4 } from "uuid";
import { Bet } from "../../models";
// import moment from "moment-timezone";
// import { useEffect, useState } from "react";
import CountUp from "react-countup";

import { getNameSymbol } from "../../utils/stock";
import { allStockData, IStocksData } from "../../utils/stock";
import { TypeOptionsHome } from "../../context/AuthContext";
import NumberScroll from "../NumberScroll/NumberScroll";
interface StockItemProps {
  item: Bet;
  infoStock: any;
  name: string;
  company: string;
  avatar: string;
  timeOption?: TypeOptionsHome;
  timeDelay?: number;
}

function StockItem({
  item,
  infoStock,
  name,
  company,
  avatar,
  timeOption,
  timeDelay,
}: StockItemProps) {
  const router = useRouter();
  const nameDisplay = getNameSymbol(name);
  // const id = uuidv4();
  // infoStock?.stock == "FB" && console.log(infoStock.volume);
  const volume = infoStock?.volume || 0;

  const greenPrice = infoStock?.lastAvgPriceGreen;
  const redPrice = 1 - infoStock?.lastAvgPriceGreen;

  const handleClickToStock = (stock: string) => {
    timeOption
      ? router.push(`/${timeOption.toLocaleLowerCase()}/${stock}`)
      : router.push(`/metrics/${stock}`);
  };

  return (
    <>
      <div
        className={clsx(
          "stockItem",
          Number(item?.volatility) < 0 && "stockItem-red"
        )}
        onClick={() => handleClickToStock(nameDisplay || name)}
      >
        <div className="stockItem_header">
          <div className="stockItem_header_name">
            <div className="stockItem_header_name_logo">
              <img
                src={
                  avatar ||
                  allStockData.find((item: IStocksData) => item?.name == name)
                    ?.avatar
                }
                alt=""
              />
            </div>

            <div className="stockItem_header_name_info">
              <p className="title">
                <span className="title_name">{nameDisplay}</span>
                {/* <h3 className="h3">
                  <span className={`number number-${id}`}></span> gals
                </h3> */}
                <span
                  className={clsx("title_price", `${nameDisplay}-price`)}
                  style={timeOption == "METRICS" ? { display: "none" } : {}}
                >
                  {item?.price > 0 && (
                    <>
                      <span>$</span>
                      <span>
                        {
                          <NumberScroll
                            value={item?.price}
                            timeDelay={timeDelay}
                          />
                        }
                        {/* {!infoStock?.volume && Number(item?.price).toFixed(2)}                     */}
                      </span>
                    </>
                  )}
                </span>
              </p>
              <p className="text">
                <span className="text_name">{company}</span>
                <span
                  className={clsx(
                    " text_price volatility",
                    String(item?.volatility) === "0.00" //  (-0.00 >= 0 => true)
                      ? "red-text"
                      : String(item?.volatility) === "0.00"
                      ? "green-text"
                      : Number(item?.volatility) < 0
                      ? "red-text"
                      : "green-text"
                  )}
                  style={timeOption == "METRICS" ? { display: "none" } : {}}
                >
                  {String(item?.volatility) === "0.00" ? (
                    "0.00%"
                  ) : String(item?.volatility) === "0.00" ? (
                    "0.00%"
                  ) : (
                    <>
                      {Number(item?.volatility) > 0 && "+"}
                      {item?.volatility
                        ? `${Number(item?.volatility).toFixed(2)}%`
                        : ""}
                    </>
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="stockItem_header_price">
            <p>${item?.price && Number(item?.price).toFixed(2)}</p>
            <p
              className={clsx(
                "volatility",
                String(item?.volatility) === "0.00" //  (-0.00 >= 0 => true)
                  ? "red-text"
                  : String(item?.volatility) === "0.00"
                  ? "green-text"
                  : Number(item?.volatility) < 0
                  ? "red-text"
                  : "green-text"
              )}
            >
              {String(item?.volatility) === "0.00" ? (
                "0.00%"
              ) : String(item?.volatility) === "0.00" ? (
                "0.00%"
              ) : (
                <>
                  {Number(item?.volatility) > 0 && "+"}
                  {item?.volatility
                    ? `${Number(item?.volatility).toFixed(2)}%`
                    : ""}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="stockItem_percent">
          <div className="stockItem_percent_hoz">
            <div
              className="hoz-green"
              style={{
                width: `${
                  infoStock?.stock
                    ? Number(
                        greenPrice == redPrice
                          ? 50
                          : greenPrice * 100 <= 20
                          ? 1
                          : greenPrice * 100 >= 80
                          ? 100
                          : ((greenPrice * 100 - 20) / 60) * 100
                      )
                    : 50
                }%`,
              }}
            ></div>
          </div>
          <div className="stockItem_percent_number">
            <p>${(greenPrice ? Number(greenPrice) : 0.5).toFixed(2)}</p>
          </div>
        </div>
        <div className="stockItem_percent">
          <div className="stockItem_percent_hoz">
            <div
              className="hoz-red"
              style={{
                width: `${
                  infoStock?.stock
                    ? Number(
                        greenPrice == redPrice
                          ? // ? 50
                            // : item?.redPercent * 100
                            50
                          : redPrice * 100 <= 20
                          ? 1
                          : redPrice * 100 >= 80
                          ? 100
                          : ((redPrice * 100 - 20) / 60) * 100
                      )
                    : 50
                }%`,
              }}
            ></div>
          </div>
          <div className="stockItem_percent_number">
            <p>
              ${(infoStock?.redPercent ? Number(redPrice) : 0.5).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="stockItem_footer">
          {/* <div className="stockItem_footer_investor">
            <img src={investorIcon.src} alt="" />
            <p>{numberBets}</p>
          </div>
          <div className="stockItem_footer_money">
            <img src={moneyIcon.src} alt="" />
            <p>${item?.total ? item?.total : 0}</p>
          </div> */}
          <div className="stockItem_footer_volume">
            <p className="stockItem_footer_volume_text">Volume:</p>
            {/* <p>{Number(item?.volume).toFixed(0)}</p> */}
            <p>
              <CountUp
                decimal={","}
                decimals={0}
                end={Number(volume || infoStock?.volume)}
                duration={0.5}
                separator=","
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default StockItem;
