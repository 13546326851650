import Head from "next/head";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Button from "../common/Button";
import { scrollToTopNormal } from "../../utils/localDom";
import { TypeOptionsHome, useAuth } from "../../context/AuthContext";
import { useRouter } from "next/dist/client/router";
import { getTopMovesAPI } from "../../utils/restClient";
import UserWinLossItem from "../UserWinLossItem";

interface TopMovesProps {
  timeOption: TypeOptionsHome;
}
export interface IUserWinLoss {
  name: string;
  img: string;
  money: number;
}

export type TypeCrypto = "" | "BTC" | "ETC" | "USDC";

function TopMoves({ timeOption = "DAILY" }: TopMovesProps) {
  const {
    user,
    setUser,
    isLogged,
    checkLogin,

    useWinLoss,
  } = useAuth();
  const {
    isWin,
    setIsWin,
    //
    usersWinDaily,
    usersLossDaily,
    setUsersLossDaily,
    setUsersWinDaily,
    //
    usersWinWeekly,
    usersLossWeekly,
    setUsersLossWeekly,
    setUsersWinWeekly,
    //
    usersWinMonthly,
    usersLossMonthly,
    setUsersLossMonthly,
    setUsersWinMonthly,

    isFetchUsersWinLoss,
    setIsFetchUsersWinLoss,
  } = useWinLoss;
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const usersWin = () => {
    if (timeOption == "DAILY") {
      return usersWinDaily;
      // && setIsFetchUsersWinLoss(true);
    } else if (timeOption == "WEEKLY") {
      return usersWinWeekly;
    } else if (timeOption == "MONTHLY") {
      return usersWinMonthly;
    }
    return [];
  };

  const usersLoss = () => {
    if (timeOption == "DAILY") {
      return usersLossDaily;
      // && setIsFetchUsersWinLoss(true);
    } else if (timeOption == "WEEKLY") {
      return usersLossWeekly;
    } else if (timeOption == "MONTHLY") {
      return usersLossMonthly;
    }
    return [];
  };

  useEffect(() => {
    scrollToTopNormal(
      (document.querySelector(".page-content") as HTMLDivElement) || undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, isLogged]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (!isFetchUsersWinLoss) {

    if (
      (timeOption == "DAILY" && !usersWinDaily?.length) ||
      (timeOption == "WEEKLY" && !usersWinWeekly?.length) ||
      (timeOption == "MONTHLY" && !usersWinMonthly?.length)
    )
    setIsLoading(true);
      Promise.all([
        getTopMovesAPI.find({
          query: {
            timeOption: timeOption,
          },
        }),
      ]).then(([{ resultsUsersLoss, resultsUsersWin }]) => {
        console.log({ resultsUsersLoss, resultsUsersWin });
        setIsLoading(false);
        if (timeOption == "DAILY") {
          resultsUsersLoss && setUsersLossDaily(resultsUsersLoss);
          resultsUsersWin && setUsersWinDaily(resultsUsersWin);
          // && setIsFetchUsersWinLoss(true);
        } else if (timeOption == "WEEKLY") {
          resultsUsersLoss && setUsersLossWeekly(resultsUsersLoss);
          resultsUsersWin && setUsersWinWeekly(resultsUsersWin);
        } else if (timeOption == "MONTHLY") {
          resultsUsersLoss && setUsersLossMonthly(resultsUsersLoss);
          resultsUsersWin && setUsersWinMonthly(resultsUsersWin);
        }
      });
    // }
  }, [
    // isFetchUsersWinLoss,
    timeOption,
  ]);

  useEffect(() => {
    checkAdmin();
    async function checkAdmin() {
      if (user) {
        if (user.admin) router.push("/daily");
      }
    }
  }, [user]);
  return (
    <div className="topMoves">
      {isWin ? (
        <div className={"usersWinLossWrapper"}>
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7].map((user: any, index: number) => (
                <UserWinLossItem
                  key={index}
                  id={index}
                  img={user.img}
                  name={user.name}
                  money={user.money}
                  isWin={true}
                  wantID={true}
                />
              ))
            : usersWin()
                ?.sort(
                  (first: IUserWinLoss, second: IUserWinLoss) =>
                    second.money - first.money
                )
                .slice(0, 7)
                .map((user: IUserWinLoss, index: number) => (
                  <UserWinLossItem
                    key={index}
                    id={index}
                    img={user.img}
                    name={user.name}
                    money={user.money}
                    isWin={true}
                    wantID={true}
                  />
                ))}
        </div>
      ) : (
        <div className={"usersWinLossWrapper"}>
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7].map((user: any, index: number) => (
                <UserWinLossItem
                  key={index}
                  id={index}
                  img={user.img}
                  name={user.name}
                  money={user.money}
                  isWin={true}
                  wantID={true}
                />
              ))
            : usersLoss()
                ?.sort(
                  (first: IUserWinLoss, second: IUserWinLoss) =>
                    second.money - first.money
                )
                .slice(0, 7)
                .map((user: IUserWinLoss, index: number) => (
                  <UserWinLossItem
                    key={index}
                    id={index}
                    img={user.img}
                    name={user.name}
                    money={user.money}
                    isWin={false}
                    wantID={true}
                  />
                ))}
        </div>
      )}
    </div>
  );
  return null;
}

// export async function getServerSideProps(ctx) {
//   return { props: await getTopMovesAPI.find() };
// }

export default TopMoves;
