import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bet } from "../../models";
import { useMediaQuery } from "react-responsive";
import StockItem from "../StockItem";

import { getNameSymbolApi, IStocks } from "../../utils/stock";
import Button from "../common/Button";
import clsx from "clsx";
import nextIcon from "../../assets/img/svg/next.svg";
import { v4 as uuidv4 } from "uuid";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TypeOptionsHome } from "../../context/AuthContext";

interface StockListProps {
  list?: IStocks;
  stocks: any;
  infoStocks: any;
  timeOption?: TypeOptionsHome;
  timeDelay?: number;
}

const StockList = ({
  list,
  stocks,
  infoStocks,
  timeOption,
  timeDelay = 2,
}): StockListProps | any => {
  // console.log({ infoStocks });
  let sliderRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: isMobile ? 2 : 4,
    speed: isMobile ? 300 : 500,
    slidesToScroll: isMobile ? 2 : 2,
    dots: false,
    arrows: false,
    // touchThreshold: 5,
    initialSlide: 0,
    // cssEase: "linear",
    autoplaySpeed: 0,
  };

  // const checkIndexSlide = (index) => {
  //   if (index == 0) {
  //     setIsBtnLeft(false);
  //   } else {
  //     setIsBtnLeft(true);
  //   }
  // };

  // start fix scrollbar investor mobile
  let firstClientX, clientX;
  const containerRef = useRef<any>(null);
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false,
        });
      }
    };
  });
  // end fix scrollbar investor mobile

  return (
    <>
      <div className={clsx("home_title")}>
        <p>{list?.name}</p>
        {/* <p className="viewAll" onClick={() => router.push("/stocks")}>
                  View all
                </p> */}
        <div className="home_title_buttons">
          <Button
            className={clsx("btnPrev")} // !isBtnLeft && "disable")
            onClick={() => {
              (sliderRef?.current as any)?.slickPrev();
            }}
          >
            <>
              <img src={nextIcon.src} alt="prev" />
            </>
          </Button>
          <Button
            className="btnNext"
            onClick={() => {
              (sliderRef?.current as any)?.slickNext();
            }}
          >
            <>
              <img src={nextIcon.src} alt="next" />
            </>
          </Button>
        </div>
      </div>
      <div
        className={clsx(
          "home_commonStocks home_commonStocks-stocksList isDesktop"
        )}
      >
        <div
          className="commonStocksWrapper commonStocksWrapper-stockList"
          ref={containerRef}
        >
          <Slider
            {...settings}
            sliderRef={sliderRef}
            // checkIndexSlide={checkIndexSlide}
          >
            {[...list?.data, ...list?.data]?.map(
              (itemStock: any, index: number) => (
                <div key={index} className="stockItemWrapper">
                  <StockItem
                    // key={index}
                    timeOption={timeOption}
                    item={stocks.find(
                      (item: Bet) =>
                        item.name === getNameSymbolApi(itemStock.name)
                    )}
                    infoStock={infoStocks.find(
                      (item: any) => item.stock === getNameSymbolApi(itemStock.name)
                    )}
                    company={itemStock.company}
                    avatar={itemStock.avatar}
                    name={itemStock.name}
                    timeDelay={timeDelay}
                  />
                </div>
              )
            )}
          </Slider>
        </div>
      </div>

      <div
        className={clsx("stockListMobile isMobile")}
        style={list?.name == "Other Stocks" ? { marginBottom: "0px" } : {}}
      >
        <ScrollMenu
        // LeftArrow={LeftArrow}
        // RightArrow={RightArrow}
        // onWheel={onWheel}
        >
          {[...list?.data]?.map((itemStock: any, index: number) => (
            <div
              key={index}
              // className="stockItemWrapper"
              className="item"
            >
              <StockItem
                // key={index}
                timeOption={timeOption}
                item={stocks.find(
                  (item: Bet) => item.name === getNameSymbolApi(itemStock.name)
                )}
                infoStock={infoStocks.find(
                  (item: any) => item.stock === getNameSymbolApi(itemStock.name)
                )}
                company={itemStock.company}
                avatar={itemStock.avatar}
                name={itemStock.name}
                timeDelay={timeDelay}
              />
            </div>
          ))}
        </ScrollMenu>
      </div>
    </>
  );
};

export default StockList;

// https://lifesaver.codes/answer/drag-on-sliders-fires-a-click-event-848

export const Slider = ({ children, sliderRef, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    console.log("handleBeforeChange");
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(
    (index) => {
      console.log("handleAfterChange", index);
      setDragging(false);
    },
    [setDragging]
  );

  const handleOnItemClick = useCallback(
    (e) => {
      // console.log("handleOnItemClick");
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <>
      <Slick
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        ref={sliderRef}
        {...props}
      >
        {React.Children.map(children, (child) => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </Slick>
    </>
  );
};
