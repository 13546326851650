import Head from "next/head";
import { Router, useRouter } from "next/router";
import React, { ChangeEvent, useEffect, useState } from "react";
import HomePage from "../../components/home/HomePage";

import { TypeOptionsHome, useAuth } from "../../context/AuthContext";

interface TimeHomePageProps {
  timeHome: "daily" | "weekly" | "monthly";
}

const TimeHomePage = ({ timeHome }: TimeHomePageProps) => {
  const { useOptionHome } = useAuth();
  const [optionHome, setOptionHome] = useOptionHome;

  const router = useRouter();

  useEffect(() => {
    if (timeHome == "daily" || timeHome == "weekly" || timeHome == "monthly") {
      setOptionHome(timeHome.toUpperCase() as TypeOptionsHome);
    } else {
      router.push("/daily");
    }
  }, [timeHome]);

  if (timeHome == "daily" || timeHome == "weekly" || timeHome == "monthly")
    return (
      <>
        <Head>
          <meta property="og:image" content="/MetatagSiteImageV5.jpeg"></meta>
          <meta
            property="twitter:image"
            content="/MetatagSiteImageV5.jpeg"
          ></meta>
        </Head>
        <HomePage
          timeOption={String(timeHome).toUpperCase() as TypeOptionsHome}
        />
      </>
    );

  return null;
};

TimeHomePage.getInitialProps = async (ctx) => {
  const {
    query: { timeHome },
  } = ctx;
  return { timeHome };
};

export default TimeHomePage;
