import React, { useEffect, useState, useRef, createRef } from "react";
import Head from "next/head";
import { useRouter } from "next/dist/client/router";
import moment from "moment-timezone";
// import CountUp from "react-countup";
import clsx from "clsx";

import { calculate, calculatePercent } from "../../utils/bet";
import { TypeOptionsHome, useAuth } from "../../context/AuthContext";
// import config from "../../config.json";

import {
  stockRestAPI,
  detailStockRestAPI,
  calendarRestAPI,
  getTopBigMoversAPI,
} from "../../utils/restClient";

import { Bet } from "../../models";
import { disableTouchElms, scrollToTopNormal } from "../../utils/localDom";
import getFeatherClient from "../../utils/featherClient";

import Button from "../common/Button";

import TopMoves, { IUserWinLoss } from "../top-moves";
import EarlyAccess from "./EarlyAccess";
import ChartSpotlight from "./ChartSpotlight";
import StockList from "../StocksList/StockList";
import BigMovers from "./BigMovers/BigMovers";
import VoteStockList from "../VoteStocksList/VoteStockList";
import InvestorList from "../InvestorList/InvestorList";
// import StockListTest from "../../components/StockListTest/StockListTest";
// import StockListMobile from "../../components/StockListMobile/StockListMobile";
import RecentTradesList from "../RecentTradesList/RecentTradesList";

import {
  majorIndicesStocks,
  techGiantsStocks,
  cryptoStocks,
  techStocks,
  memeStocks,
  fanVotesStocks,
  growthStocks,
  otherStocks,
  allStockData,
  getInfoStock,
  votesNewStocks,
  IStocksData,
  consumerStocks,
} from "../../utils/stock";

// import useWindowSize from "../../hook/useWindowSize";

import { IDataChart } from "../symbol/ChartSymbolItem/ChartSymbol";
import { IStocksDataBigMovers } from "./BigMovers/BigMovers";
import MetricsCharts from "./MetricsCharts/MetricsCharts";
import ClainMoney from "./ClaimMoney";
import StockListBox from "../StocksList/StockListBox";
import SectorBox from "./SectorBox";

// import HomeLeftImg from "../../assets/img/svg/imgLeftHome.svg";

interface IChartSpotlight {
  data: IDataChart[];
  stockHaveVolumeMax?: any;
}

export interface IItemRealtime {
  symbol: string;
  price: number;
}
interface HomePageProps {
  timeOption: TypeOptionsHome;
}

function HomePage({ timeOption }: HomePageProps) {
  //start home
  const { user, search, setSearch, useIsShowPopup, useWinLoss } = useAuth();
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
    valueEmail,
    setValueEmail,
  ] = useIsShowPopup;
  const { isWin, setIsWin } = useWinLoss;

  const [isViewAllStockTrend, setIsViewAllStockTrend] =
    useState<boolean>(false);

  //end home
  const [isRealtimeStock, setIsRealtimeStock] = useState<boolean>(false);
  const [stocks, setStocks] = useState<any[]>([]);
  const [infoStocksDaily, setInfoStocksDaily] = useState<any[]>([]);
  const [infoStocksWeekly, setInfoStocksWeekly] = useState<any[]>([]);
  const [infoStocksMonthly, setInfoStocksMonthly] = useState<any[]>([]);
  const [chartSpotlightDaily, setChartSpotlightDaily] =
    useState<IChartSpotlight>({
      data: [],
    });
  const [chartSpotlightWeekly, setChartSpotlightWeekly] =
    useState<IChartSpotlight>({
      data: [],
    });
  const [chartSpotlightMonthly, setChartSpotlightMonthly] =
    useState<IChartSpotlight>({
      data: [],
    });
  const [isFindDoneSpotlight, setIsFindDoneSpotlight] =
    useState<boolean>(false);

  const router = useRouter();

  const selectedChartSpotlight = () => {
    return timeOption == "DAILY"
      ? chartSpotlightDaily
      : timeOption == "WEEKLY"
      ? chartSpotlightWeekly
      : chartSpotlightMonthly;
  };
  const selectedInfoStocks = () => {
    return timeOption == "DAILY"
      ? infoStocksDaily
      : timeOption == "WEEKLY"
      ? infoStocksWeekly
      : infoStocksMonthly;
  };

  const infoStockHaveVolumeMax = () => {
    // const maxVolume = Math.max(...stocks.map((item: Bet) => item.volume));

    return {
      ...stocks.find(
        (item: Bet) =>
          item.name == selectedChartSpotlight()?.stockHaveVolumeMax?.stock
      ),
      ...selectedChartSpotlight()?.stockHaveVolumeMax,
    };
  };

  const fetchStocks = () => {
    stockRestAPI.find().then((resStocks) => {
      console.log({ resStocks });
      setStocks(
        resStocks.map((item: any) => {
          return {
            id: item.symbol,
            name: item.symbol,
            price: item.currentBar?.c,
            recentPrice: item.recentBar?.c,
            volatility:
              item?.currentBar?.c && item?.recentBar?.c
                ? (
                    ((item.currentBar.c - item.recentBar.c) * 100) /
                    item.recentBar.c
                  )?.toFixed(2)
                : "",
            greenPercent: 0,
            redPercent: 0,
            total: 0,
          };
        })
      );
    });
  };

  const fetchDetailStockAndSpotlight = (timeOption: TypeOptionsHome) => {
    setIsFindDoneSpotlight(false);
    // abortController.current = new AbortController();
    detailStockRestAPI
      .find({
        query: {
          timeOption: timeOption,
          isChartSpotlight: true,
        },
        // connection: {
        //   signal: abortController.current.signal,
        // },
      })
      .then((resDetailStock) => {
        console.log({ resDetailStock });

        resDetailStock?.data && timeOption == "DAILY"
          ? setInfoStocksDaily(resDetailStock?.data)
          : timeOption == "WEEKLY"
          ? setInfoStocksWeekly(resDetailStock?.data)
          : setInfoStocksMonthly(resDetailStock?.data);

        // chart spotlight , need more speed load web => update api detailStock, find stockMaxVol => chartStockMaxVol
        resDetailStock.chartSpotlight && timeOption == "DAILY"
          ? setChartSpotlightDaily(resDetailStock.chartSpotlight)
          : timeOption == "WEEKLY"
          ? setChartSpotlightWeekly(resDetailStock.chartSpotlight)
          : setChartSpotlightMonthly(resDetailStock.chartSpotlight);

        setIsFindDoneSpotlight(true);
        // fetchBigMovers();
      });
  };

  useEffect(() => {
    // stocksRef.current = stocks;
    // timeCheckRef.current = timeCheck;
  });

  useEffect(() => {
    scrollToTopNormal(
      (document.querySelector(".page-content") as HTMLDivElement) || undefined
    );
    fetchStocks();
  }, []);

  useEffect(() => {
    // Get all stock
    // abortController.current && abortController.current.abort();
    if (timeOption == "DAILY") {
      !chartSpotlightDaily.data.length && fetchDetailStockAndSpotlight("DAILY");
    } else if (timeOption == "WEEKLY") {
      !chartSpotlightWeekly.data.length &&
        fetchDetailStockAndSpotlight("WEEKLY");
    } else if (timeOption == "MONTHLY") {
      !chartSpotlightMonthly.data.length &&
        fetchDetailStockAndSpotlight("MONTHLY");
    }
  }, [timeOption]);

  useEffect(() => {
    if (!isRealtimeStock) {
      if (
        stocks.length &&
        ((infoStocksDaily.length && chartSpotlightDaily.data.length) ||
          (infoStocksWeekly.length && chartSpotlightWeekly.data.length) ||
          (infoStocksMonthly.length && chartSpotlightMonthly.data.length))
      ) {
        setIsRealtimeStock(true);
        console.log("-realTime stocks-");
        const stocksService = getFeatherClient().service("stocks");
        stocksService.on("check_connected_alpaca", (data: any) =>
          console.log("check_connected_alpaca", data)
        );
        stocksService.on("update_stock_alpaca", async (data: any) => {
          // console.log({ data });
          const symbolsNames = data?.data.map(
            (itemRT: IItemRealtime) => itemRT.symbol
          );

          setStocks((pevStocks) => {
            // console.log({ pevStocks });
            return pevStocks.map((item: any) => {
              if (symbolsNames.includes(item.name)) {
                // console.log({data});
                const closePrice = data?.data.find(
                  (itemRT: IItemRealtime) => itemRT.symbol == item.name
                )?.price;
                // console.log(item.name, closePrice);
                // console.log({ item }, item.name, closePrice, item.recentPrice);
                return {
                  ...item,
                  price: Number(closePrice),
                  volatility:
                    closePrice && item?.recentPrice
                      ? (
                          ((Number(closePrice) - item.recentPrice) * 100) /
                          item.recentPrice
                        )?.toFixed(2)
                      : "",
                };
              }
              return item;
            });
          });
          // console.log("--------------");
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setStocks,
    isRealtimeStock,
    infoStocksDaily,
    infoStocksWeekly,
    infoStocksMonthly,
    chartSpotlightDaily,
    chartSpotlightWeekly,
    chartSpotlightMonthly,
  ]);

  useEffect(() => {
    return () => {
      console.log("- remove realtime stock -");
      const stocksService = getFeatherClient().service("stocks");
      // Clean up listeners
      stocksService.removeListener("check_connected_alpaca");
      stocksService.removeListener("update_stock_alpaca");
    };
  }, []);

  useEffect(() => {
    checkAdmin();
    async function checkAdmin() {
      if (user) {
        if (user.admin) router.push("/daily");
      }
    }
  }, [user]);

  useEffect(() => {
    const checkAuth = localStorage.getItem("auth");
    const earlyAccessElm = document.querySelector(
      ".home_top_earlyAccess"
    ) as HTMLElement;
    if (!checkAuth) {
      earlyAccessElm && (earlyAccessElm.style.display = "flex");
    } else {
      earlyAccessElm && (earlyAccessElm.style.display = "none");
    }

    if (user._id) {
      earlyAccessElm && (earlyAccessElm.style.display = "none");
    }
  }, [user, router]);

  if ((Object.keys(user).length > 0 && user._id) || !user._id)
    return (
      <>
        <div className="home">
          {timeOption ? (
            <>
              <div className="home_top">
                <EarlyAccess />
                <div className="home_top_left">
                  <div className="homeChart">
                    <div className="title">
                      <p>Stockbet Spotlight</p>
                    </div>
                    <ChartSpotlight
                      timeOption={timeOption}
                      dataChart={selectedChartSpotlight()?.data}
                      infoBet={infoStockHaveVolumeMax()}
                      isLoadingHeader={isFindDoneSpotlight}
                    />
                  </div>
                </div>
                <div className="home_top_right home_top_right-investors">
                  <div className="homeTopInvestors">
                    <div className="homeTopInvestors_head">
                      <p className="title">Top Investors</p>
                      <div
                        className={clsx(
                          "topMoves_options"
                          // isWinLoss ? "topMoves_options-win" : "topMoves_options-loss"
                        )}
                      >
                        <div
                          className={clsx(
                            "optionsWrapper",
                            `optionsWrapper-${isWin ? "win" : "loss"}`
                          )}
                        >
                          <Button
                            className="btnWin"
                            text="Win"
                            active={isWin}
                            onClick={() => setIsWin(true)}
                          />
                          <Button
                            className="btnLoss"
                            text="Loss"
                            active={!isWin}
                            onClick={() => setIsWin(false)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="homeTopInvestors_lists">
                      <TopMoves timeOption={timeOption} />
                    </div>
                  </div>
                </div>
                <div className="home_spotlight_title"></div>
              </div>

              <ClainMoney />

              <div className="home_title home_title-topInvestors">
                <p>Top Investors</p>
                <div
                  className={clsx(
                    "topMoves_options"
                    // isWinLoss ? "topMoves_options-win" : "topMoves_options-loss"
                  )}
                >
                  <div
                    className={clsx(
                      "optionsWrapper",
                      `optionsWrapper-${isWin ? "win" : "loss"}`
                    )}
                  >
                    <Button
                      className="btnWin"
                      text="Win"
                      active={isWin}
                      onClick={() => setIsWin(true)}
                    />
                    <Button
                      className="btnLoss"
                      text="Loss"
                      active={!isWin}
                      onClick={() => setIsWin(false)}
                    />
                  </div>
                </div>
              </div>

              <InvestorList timeOption={timeOption} />

              <StockList
                timeOption={timeOption}
                list={majorIndicesStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={3}
              />

              <StockList
                timeOption={timeOption}
                list={{
                  ...techGiantsStocks,
                  data: techGiantsStocks.data.slice(0, 4),
                }}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={3}
              />

              <BigMovers
                timeOption={timeOption}
                // dataStocks={dataStocksBigMovers}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                isFindDoneSpotlight={isFindDoneSpotlight}
                checkDaily={chartSpotlightDaily.data.length || 0}
                checkWeekly={chartSpotlightWeekly.data.length || 0}
                checkMonthly={chartSpotlightMonthly.data.length || 0}
              />

              <StockList
                timeOption={timeOption}
                list={memeStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={5}
              />

              <div className="home_item">
                <div className={clsx("home_title home_title-50-1")}>
                  <p>{"Tech Stocks"}</p>
                </div>
                <div className={clsx("home_title home_title-50-2 isDesktop")}>
                  <p>{"Sector Market"}</p>
                </div>
                <StockListBox
                  timeOption={timeOption}
                  list={techStocks}
                  stocks={stocks}
                  infoStocks={selectedInfoStocks()}
                  timeDelay={6}
                />

                <div className={clsx("home_title home_title-50-2 isMobile")}>
                  <p>{"Sector Market"}</p>
                </div>

                <SectorBox timeOption={timeOption} />
              </div>

              <StockList
                timeOption={timeOption}
                list={cryptoStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={5}
              />

              {/* <RecentTradesList /> */}

              <MetricsCharts
              // timeOption={timeOption}
              // dataStocks={dataStocksBigMovers}
              // stocks={stocks}
              // infoStocks={selectedInfoStocks()}
              />

              <StockList
                timeOption={timeOption}
                list={growthStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={6}
              />

              {/* fan vote */}

              <StockList
                timeOption={timeOption}
                list={fanVotesStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={6}
              />

              {/* Vote New Stocks */}

              <VoteStockList
                list={votesNewStocks}
                isFindDoneSpotlight={isFindDoneSpotlight}
              />

              {/* End Vote New Stocks */}

              <StockList
                timeOption={timeOption}
                list={consumerStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={7}
              />

              <StockList
                timeOption={timeOption}
                list={otherStocks}
                stocks={stocks}
                infoStocks={selectedInfoStocks()}
                timeDelay={7}
              />
            </>
          ) : (
            <>
              <div className="home_comingSoon">
                <p>Coming soon...</p>
              </div>
            </>
          )}
        </div>
      </>
    );

  return null;
}

// export async function getServerSideProps(ctx) {
//   // Get all stock
//   const fetchStocks = stockRestAPI.find().then((bars) => {
//     return bars.map((item: any) => {
//       return {
//         id: item.symbol,
//         name: item.symbol,
//         price: item.currentBar?.c,
//         recentPrice: item.recentBar?.c,
//         volatility:
//           item?.currentBar?.c && item?.recentBar?.c
//             ? (
//                 ((item.currentBar.c - item.recentBar.c) * 100) /
//                 item.recentBar.c
//               )?.toFixed(2)
//             : "",
//         greenPercent: 0,
//         redPercent: 0,
//         total: 0,
//       };
//     });
//   });
//   // Get close day
//   const getCloseDate = async () => {
//     const tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1)); //get next date from to day
//     const tomorrowDateTz = moment(tomorrowDate)
//       .tz(config.TIMEZONE_NEW_YORK)
//       .format("YYYY-MM-DD");
//     const res = await calendarRestAPI.find({
//       query: {
//         date: { $gte: tomorrowDateTz },
//         $sort: { date: 1 },
//         $limit: 1,
//       },
//     });
//     const day = res.data[0];
//     if (day?.date) {
//       return moment(day.date).format("dddd");
//     }
//     return "";
//   };

//   return {
//     props: await Promise.all([
//       fetchStocks,
//       getCloseDate(),
//       detailStockRestAPI.find(),
//     ]).then(async ([stocks, closeDay, valuesBet]) => {
//       if (closeDay !== "" && stocks.length > 0) {
//         const bets = valuesBet.map((value) =>
//           value.data[0] ? value.data[0] : {}
//         );
//         return {
//           initialStocks: stocks.map((stock) => {
//             const bet = bets.filter((b) => b.stock === stock.name)[0] as any;
//             if (bet) {
//               return {
//                 ...stock,
//                 total: bet.total,
//                 greenPercent: calculatePercent(bet.total, bet.greenBets),
//                 redPercent: 100 - calculatePercent(bet.total, bet.greenBets),
//               };
//             }
//             return stock;
//           }),
//           closeDay,
//         };
//       }
//     }),
//   };
// }

export default HomePage;
