import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bet } from "../../../models";
import { useMediaQuery } from "react-responsive";
//
import BigMoversItem from "./BigMoversItem";

import {
  allStockData,
  getNameSymbol,
  IStocks,
  IStocksData,
} from "../../../utils/stock";
import Button from "../../common/Button";
import clsx from "clsx";

// import { getTopBigMoversAPI } from "../../utils/restClient";

// import { allStockData, IStocksData } from "../../utils/stock";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IDataChart } from "../../symbol/ChartSymbolItem/ChartSymbol";
import { TypeOptionsHome } from "../../../context/AuthContext";
import { getTopBigMoversAPI } from "../../../utils/restClient";

import nextIcon from "../../../assets/img/svg/next.svg";

interface BigMoversProps {
  timeOption: TypeOptionsHome;
  // dataStocks: IStocksDataBigMovers[];
  list?: IStocks;
  stocks: any;
  infoStocks: any;
  isFindDoneSpotlight: boolean;
}

export interface IStocksDataBigMovers {
  name: string;
  priceDifference: number;
  dataChart: IDataChart[];
}

const BigMovers = ({
  timeOption = "DAILY",
  // dataStocks,
  stocks,
  infoStocks,
  isFindDoneSpotlight = false,
  checkDaily,
  checkWeekly,
  checkMonthly,
}): BigMoversProps | any => {
  const [dataStocksDaily, setDataStocksDaily] = useState<
    IStocksDataBigMovers[]
  >([]);

  const [dataStocksWeekly, setDataStocksWeekly] = useState<
    IStocksDataBigMovers[]
  >([]);

  const [dataStocksMonthly, setDataStocksMonthly] = useState<
    IStocksDataBigMovers[]
  >([]);

  const selectedDataStocks = () => {
    return timeOption == "DAILY"
      ? dataStocksDaily
      : timeOption == "WEEKLY"
      ? dataStocksWeekly
      : dataStocksMonthly;
  };

  let sliderRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: isMobile ? 2 : 3,
    speed: isMobile ? 300 : 500,
    slidesToScroll: isMobile ? 1 : 2,
    dots: false,
    arrows: false,
    // touchThreshold: 5,
    initialSlide: 0,
    // cssEase: "linear",
    autoplaySpeed: 0,
  };

  // start fix scrollbar investor mobile
  let firstClientX, clientX;
  const containerRef = useRef<any>(null);
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false,
        });
      }
    };
  });
  // end fix scrollbar investor mobile

  const fetchBigMovers = (timeOption: TypeOptionsHome) => {
    getTopBigMoversAPI
      .find({
        query: {
          timeOption,
        },
        // connection: {
        //   signal: abortController.current.signal,
        // },
      })
      .then((resBigMovers) => {
        console.log({ resBigMovers });
        const dataStocksFind = resBigMovers.map((item: IStocksData) => {
          const dataOthers = allStockData.find(
            (stockData) => stockData.name == item.name
          );
          return {
            ...item,
            ...dataOthers,
          };
        });
        timeOption == "DAILY"
          ? setDataStocksDaily(dataStocksFind)
          : timeOption == "WEEKLY"
          ? setDataStocksWeekly(dataStocksFind)
          : setDataStocksMonthly(dataStocksFind);
      });
  };

  useEffect(() => {
    // console.log({ checkDaily, checkWeekly, checkMonthly });
    if (timeOption == "DAILY") {
      !dataStocksDaily.length &&
        //   isFindDoneSpotlight &&
        // checkDaily &&
        fetchBigMovers("DAILY");
    } else if (timeOption == "WEEKLY") {
      !dataStocksWeekly.length &&
        // isFindDoneSpotlight &&
        // checkWeekly &&
        fetchBigMovers("WEEKLY");
    } else if (timeOption == "MONTHLY") {
      !dataStocksMonthly.length &&
        // isFindDoneSpotlight &&
        // checkMonthly &&
        fetchBigMovers("MONTHLY");
    }
  }, [
    timeOption,
    // isFindDoneSpotlight,
    // checkDaily, checkWeekly, checkMonthly
  ]);

  return (
    <>
      <div className="home_title">
        <p>Big Movers</p>
        {/* <p className="viewAll" onClick={() => router.push("/stocks")}>
                  View all
                </p> */}
        <div className="home_title_buttons">
          <Button
            className={clsx("btnPrev")} // !isBtnLeft && "disable")
            onClick={() => {
              (sliderRef?.current as any)?.slickPrev();
            }}
          >
            <>
              <img src={nextIcon.src} alt="prev" />
            </>
          </Button>
          <Button
            className="btnNext"
            onClick={() => {
              (sliderRef?.current as any)?.slickNext();
            }}
          >
            <>
              <img src={nextIcon.src} alt="next" />
            </>
          </Button>
        </div>
      </div>

      <div className="home_commonStocks home_commonStocks-bigMovers">
        <div className="commonStocksWrapper isDesktop" ref={containerRef}>
          <Slider
            {...settings}
            sliderRef={sliderRef}
            // checkIndexSlide={checkIndexSlide}
          >
            {(selectedDataStocks().length
              ? [...selectedDataStocks()]
              : [1, 2, 3, 4]
            )?.map((itemStock: any, index: number) => (
              <div key={index} className="bigMoversItemWrapper">
                <BigMoversItem
                  key={index}
                  timeOption={timeOption}
                  item={stocks.find(
                    (item: Bet) => item.name === itemStock.name
                  )}
                  infoStock={infoStocks.find(
                    (item: any) => item.stock === itemStock.name
                  )}
                  volume={itemStock.volume}
                  company={itemStock.company}
                  avatar={itemStock.avatar}
                  dataChart={itemStock.dataChart}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="bigMoversMobile isMobile">
          <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          // onWheel={onWheel}
          >
            {(selectedDataStocks().length
              ? [...selectedDataStocks()]
              : [1, 2, 3, 4]
            )?.map((itemStock: any, index: number) => (
              <div key={index} className="bigMoversItemWrapper">
                <BigMoversItem
                  key={index}
                  timeOption={timeOption}
                  item={stocks.find(
                    (item: Bet) => item.name === itemStock.name
                  )}
                  infoStock={infoStocks.find(
                    (item: any) => item.stock === itemStock.name
                  )}
                  volume={itemStock.volume}
                  company={itemStock.company}
                  avatar={itemStock.avatar}
                  dataChart={itemStock.dataChart}
                />
              </div>
            ))}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
};

export default BigMovers;

// https://lifesaver.codes/answer/drag-on-sliders-fires-a-click-event-848

export const Slider = ({ children, sliderRef, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    console.log("handleBeforeChange");
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(
    (index) => {
      console.log("handleAfterChange", index);
      setDragging(false);
    },
    [setDragging]
  );

  const handleOnItemClick = useCallback(
    (e) => {
      // console.log("handleOnItemClick");
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <>
      <Slick
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        ref={sliderRef}
        {...props}
      >
        {React.Children.map(children, (child) => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </Slick>
    </>
  );
};
