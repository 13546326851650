import clsx from "clsx";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";

import { Bet } from "../../../../models";
import getFeatherClient from "../../../../utils/featherClient";

import { v4 as uuidv4 } from "uuid";

import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  BarChart,
  CartesianGrid,
  Bar,
  Legend,
} from "recharts";
import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { getNameSymbol } from "../../../../utils/stock";
import useWindowSize from "../../../../hook/useWindowSize";
import { IDataChart } from "../../../symbol/ChartSymbolItem/ChartSymbol";
import { TypeOptionsHome } from "../../../../context/AuthContext";
import SkeletonElement from "../../../SkeletonElement";

interface BigMoversItemProps {
  timeOption: TypeOptionsHome | any;
  name: string;
  company: string;
  avatar: string;
  content: string;
  dataChart: IDataChart[];
}

function MetricsChartItem({
  timeOption,
  name,
  company,
  avatar,
  content,
  dataChart = [],
}: BigMoversItemProps) {
  const [selectedChart, setSelectedChart] = useState<any>(null);
  const router = useRouter();
  const windowSize = useWindowSize();

  const handleClickToStock = (stock: string) => {
    router.push(`/metrics/${stock}`);
  };
  // const name = getNameSymbol(item?.name || infoStock?.stock);

  const client = getFeatherClient();

  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });

  const idRadom = useRef(uuidv4());

  return (
    <>
      <div
        className={clsx("metricsChartItem")}
        onClick={() => handleClickToStock(name)}
      >
        <div className="metricsChartItem_header">
          <div className="metricsChartItem_header_name">
            <div className="metricsChartItem_header_name_logo">
              <img src={avatar} alt="" />
              {/* {!dataChart.length && ( */}
              <SkeletonElement
                theme="dark"
                className="skeleton-avatarMetricsChart"
              />
              {/* )} */}
            </div>

            <div className="metricsChartItem_header_name_info">
              <p className="title">
                {dataChart.length ? (
                  content
                ) : (
                  <>
                    <SkeletonElement
                      theme="dark"
                      className="skeleton-titleMetricsChart"
                    />
                    <SkeletonElement
                      theme="dark"
                      className="skeleton-titleMetricsChart-2"
                    />
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div
          className="chartStock_chartOne"
          onTouchEnd={() => {
            // // console.log("onTouchEnd");
            // setSelectedChart(null);
            // const dotElm = document.querySelector(
            //   `#chartStock_chartOne_activeDot-${item?.name}-${idRadom}`
            // ) as HTMLElement;
            // dotElm.style.left = -10000 + "px";
            // dotElm.style.top = -10000 + "px";
          }}
          onTouchCancel={() => {
            // // console.log("onTouchCancel");
            // setSelectedChart(null);
            // const dotElm = document.querySelector(
            //   `#chartStock_chartOne_activeDot-${item?.name}-${idRadom}`
            // ) as HTMLElement;
            // dotElm.style.left = -10000 + "px";
            // dotElm.style.top = -10000 + "px";
          }}
        >
          <ResponsiveContainer width="100%" height={isMobile ? 100 : 130}>
            <AreaChart
              data={
                dataChart.length
                  ? dataChart
                  : [
                      { green: 0.5, red: 0.5 },
                      { green: 0.5, red: 0.5 },
                    ]
              }
              // data={dataChart?.map((item: any) => ({
              //   ...item,
              //   raw: 0.5,
              // }))}
              margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
              // onMouseMove={handleMoveChart}
              onMouseLeave={() => {
                const dotElm = document.querySelector(
                  `#chartStock_chartOne_activeDot-${name}-${idRadom.current}`
                ) as HTMLElement;
                dotElm.style.left = -10000 + "px";
                dotElm.style.top = -10000 + "px";
              }}
            >
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="1%" stopColor="#7f72da" stopOpacity={0.2} />
                  <stop offset="99%" stopColor="#7f72da" stopOpacity={0} />
                </linearGradient>
              </defs>
              <YAxis type="number" domain={[0.35, 0.65]} hide={true} />
              <CartesianGrid
                strokeDasharray="2 2"
                vertical={false}
                horizontalPoints={[isMobile ? 100 / 2 : 130 / 2 - 1]}
                stroke="#6C6E71"
              />
              {/* <Line
                type="linear"
                dataKey={"raw"}
                strokeDasharray="2 2"
                stroke="gray"
                dot={false}
                dotWidth={0}
                activeDot={{
                  stroke: "red",
                  strokeWidth: 0,
                  r: 0,
                }}
              /> */}
              <Area
                // activeDot={{ stroke: "red", strokeWidth: 0, r: 4 }}
                isAnimationActive={true}
                animationDuration={300}
                type="linear"
                dataKey={"green"}
                stroke="#9176E0"
                strokeWidth={2}
                // dot={false}
                dot={(props) => {
                  const { index, cx, cy, stroke, payload, value } = props;
                  if (index == dataChart.length - 1) {
                    const ringContainerElm = document.querySelector(
                      `#ringContainer-${name}-${idRadom.current}`
                    ) as HTMLElement;

                    // console.log({ ringContainerElm });
                    if (ringContainerElm) {
                      ringContainerElm.style.left = `${cx - 4}px`;
                      ringContainerElm.style.top = `${cy - 4}px`;
                      ringContainerElm.style.display = "block";
                    }
                  }

                  return null;
                }}
                dotWidth={2}
                fillOpacity={1}
                fill="url(#colorValue)"
                activeDot={(payload) => {
                  // console.log({ payload });
                  setSelectedChart(
                    dataChart.find(
                      (item: IDataChart) => item.time == payload?.payload.time
                    )
                  );
                  const dotElm = document.querySelector(
                    `#chartStock_chartOne_activeDot-${name}-${idRadom.current}`
                  ) as HTMLElement;

                  // console.log({ dotElm }, payload?.cx, payload?.cy);
                  if (dotElm) {
                    dotElm.style.left = payload?.cx + "px";
                    dotElm.style.top = payload?.cy + "px";
                  }
                  return <></>;
                }}
              />
              <Tooltip
                cursor={false}
                active={true}
                content={
                  <>
                    <div className="chartStockTooltip">
                      <div className="time">
                        <p>{selectedChart?.time}</p>
                      </div>
                      <div className="price">
                        <p className="price_green">
                          {selectedChart?.green.toFixed(2)}
                        </p>
                        <p className="price_red">
                          {selectedChart?.red.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </>
                }
              />
            </AreaChart>
          </ResponsiveContainer>
          {/* <div className="chartStock_chartOne_lineHoz"></div> */}
          <span
            className="chartStock_chartOne_activeDot"
            id={`chartStock_chartOne_activeDot-${name}-${idRadom.current}`}
          />

          <div
            id={`ringContainer-${name}-${idRadom.current}`}
            className="ring-container"
          >
            <div className="ringring"></div>
            <div className="circle"></div>
          </div>
        </div>

        <div className="chartStock_chartButton">
          <button
            className="btn-green"
            style={{
              width: `${
                dataChart
                  ? Number(
                      dataChart[dataChart.length - 1]?.green ==
                        dataChart[dataChart.length - 1]?.red
                        ? 50
                        : dataChart[dataChart.length - 1]?.green * 100
                    )
                  : 0
              }%`,
            }}
          >
            Green $
            {(dataChart[dataChart.length - 1]?.green
              ? Number(dataChart[dataChart.length - 1]?.green)
              : 0.5
            ).toFixed(2)}
          </button>
          <button
            className="btn-red"
            style={{
              width: `${
                dataChart
                  ? Number(
                      dataChart[dataChart.length - 1]?.green ==
                        dataChart[dataChart.length - 1]?.red
                        ? 50
                        : dataChart[dataChart.length - 1]?.red * 100
                    )
                  : 0
              }%`,
            }}
          >
            Red $
            {(dataChart[dataChart.length - 1]?.red
              ? Number(dataChart[dataChart.length - 1]?.red)
              : 0.5
            ).toFixed(2)}
          </button>
        </div>
      </div>
    </>
  );
}

export default MetricsChartItem;
